













































import AstCheckbox from '@components/Checkbox.vue'
import ImageFileInput from '@components/ImageFileInput.vue'
import state from '@services/reportService'
import { openNewReportTemplateDlg } from '@reporting/dialogs/NewReportTemplateDlg.vue'
import { TemplateSetStatus } from '@reporting/classes'
import TemplateSet from '@reporting/components/TemplateSet.vue'
import { showConfirm } from '@dialogs/ConfirmDlg.vue'
import { openAnalyzeReports } from '@dialogs/AnalyzeReports.vue'
import { userData } from '@services/userData'
import api, { Annex } from '@services/api'

export default {
	name: 'ReportsSettingView',
	components: {
		AstCheckbox,
		ImageFileInput,
		TemplateSet,
	},
	data() {
		return {
			sets: [],
			reporting: false,
			state,
			showRetired: false,
			isLoading: false,
			logoUrl: null,
		}
	},
	computed: {
		filteredSets() {
			return this.sets.filter(s => this.showRetired || s.status !== TemplateSetStatus.Retired)
		},
	},
	async created() {
		this.isLoading = true
		this.sets = await state.getTemplateSets(true)
		this.isLoading = false

		const cc = userData.claims.activeClinicCode
		const rand = Math.random()
		const base = Annex.defaults.baseURL
		// TODO: confirm that this will work, overloading the logo endpoint
		this.logoUrl = `${base}/logo.aspx?ClinicCode=${cc}&Type=logo&invalidate=${rand}`
	},
	methods: {
		openAnalyzeReports,
		async newWorkflow() {
			let setid = await openNewReportTemplateDlg('Report')
			if (setid) {
				this.$router.push({
					name: 'report-editor',
					params: { setId: setid },
				})
			}
		},
		async deleteWorkflow(set: ITemplateSet) {
			let confirmed = await showConfirm('Are you sure you want to delete this workflow?')
			if (confirmed) {
				state.setTemplateSetStatus(set, TemplateSetStatus.Deleted)
				this.sets.splice(this.sets.indexOf(set), 1)
			}
		},
		selectLogoFile({ target }) {
			if (!target.files.length) return
			let file = target.files[0]
			const reader = new FileReader()
			reader.addEventListener(
				'load',
				async () => {
					try {
						await api.clinic.updateLogo(file)
						this.logoUrl = reader.result
					} catch (err) {
						// TODO: user error message?
						console.error(err)
					}
				},
				false
			)
			reader.readAsDataURL(file)
		},
		async clearLogo() {
			try {
				await api.clinic.updateLogo(null)
				this.logoUrl = null
			} catch (err) {
				console.error(err)
			}
		},
	},
}
